@import "../../scss/colors.scss";

.RadarPage {
  background-color: $grey-background;
  min-height: 90vh;
  select {
    margin: 0px 0 40px 0;
  }
  span.answer {
    font-size: 14px;
  }
  .accordion-button {
    padding: 0 20px;
  }
  .accordeon-page-info {
    font-size: 16px;
  }
}

.radar-print {
  h1 {
    margin-left: 50px;
  }
}

.answers-print {
  p {
    font-size: 14px;
    line-height: 14px;
  }
  ul {
    h3 {
      margin-top: 20px;
    }
    list-style-type: none;
  }
}
