.polarChart {
  .custom-tooltip {
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    max-width: 500px;

    .label {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .desc {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
  }
  .recharts-default-legend {
    margin-left: 100px !important;
  }
  .recharts-legend-item-text {
    color: black !important;
  }

  .label-wrapper {
    width: 400px;
    height: 200px;
    .custom-label {
      background-color: transparent;
      color: black;

      h3 {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 5px 0;
      }
      p {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
