.conditional-nav-link {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 100%;

  @media only screen and (min-width: 990px) and (max-width: 1225px) {
    font-size: 14px;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  color: #404040;
  &.active {
    background-color: #2c608254;
  }
}

.signInOut {
  font-size: 18px;
  color: black !important;
  font-weight: 900 !important;
  border-radius: 8px !important;
}

.footer {
  img {
    width: 200px;
  }
}

#footer-nav {
  max-height: 200px;
  min-height: 200px;
  background-color: white !important;
  h3 {
    font-size: 18px;
    font-weight: 700;
  }
  a {
    font-size: 18px;
    color: black !important;
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
}

.username {
  font-size: 18px !important;
  font-weight: 400 !important;
  margin-right: 5px;
  @media only screen and (min-width: 1400px) {
    margin-right: 10px;
  }
}
