@import "../../scss/colors.scss";

.next-step {
  .bullet {
    padding-right: 28px;
    padding-left: 50px;
  }
  h3 {
    font-weight: 700;
  }
  br {
    display: block;
    margin: 5px 0;
  }
  span.single-line {
    display: block;
    margin: 5px 0;
  }
  p {
    a {
      color: black;
    }
  }
}
