@import "../../scss/colors.scss";

.compare-pages {
  background-color: $grey-background;
  min-height: 90vh;
}

.gauge-card {
  padding-top: 10px;
  padding-bottom: 10px;
  .gauge-card-content {
    padding: 20px;
    background-color: #f3e9e0;
    overflow: hidden;
    .title {
      height: 80px;
      h3 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
    }
    .reset-row {
      font-size: 16px;
      color: #aaaaaa;
      span {
        display: block;
        font-size: 20px;
        font-weight: 700;
        margin-right: 20px;
        color: #636262;
      }
      a {
        margin-right: 20px;
        cursor: pointer;
        color: #636262;
        &:hover {
          color: #363636;
        }
      }
    }

    .dc-html-legend {
      float: none;
      .dc-legend-item-vertical {
        display: flex;
        .dc-legend-item-label {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }

    .pie-label {
      font-family: "proxima-nova";
      font-size: 14px;
      font-weight: 700;
    }

    g.row-fix text {
      fill: black;
      font-weight: 700;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
