@import "../../../scss/colors.scss";

.CurrentSurvey {
  background-color: $grey-background;
  min-height: 90vh;

  .stooltip {
    position: relative;
    cursor: pointer;
  }

  .stooltip-checkbox {
    margin-left: 10px;
  }

  .stooltip-matrix {
    margin-left: 10px;
  }

  .stooltip-state {
    margin-left: 10px;
  }

  .sp_100 {
    padding: 0;
  }

  .sv_row {
    font-family: "proxima-nova";
    font-size: 18px;
  }

  .sv_row h2 {
    margin-top: 20px;
    font-size: 32px;
    font-weight: 600;
  }

  .sv_row p {
    font-size: 18px;
  }

  .sv_page_title {
    margin: 40px 0 20px;
  }

  .sv_page_title span.sv-string-viewer {
    font-size: 32px;
    font-weight: 700;
  }

  .info-icon {
    margin-top: -4px;
    width: 16px;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .stooltip:hover .stooltiptext {
    visibility: visible;
  }

  .sv_main.sv_default_css .sv_body {
    border: 0 !important;
    margin-bottom: 80px !important;
  }

  .sv_nav {
    height: 70px;
    .sv_next_btn,
    .sv_complete_btn {
      background-color: #00a87b !important;
      padding: 8px 45px !important;
      text-align: center;
      display: inline-block !important;
      font-size: 18px !important;
      border-radius: 25px !important;
      height: 52px;
      color: white;
      margin-top: 10px;
      text-transform: uppercase;
    }
  }

  .sv_prev_btn {
    background-color: white !important;
    padding: 8px 45px !important;
    text-align: center;
    display: inline-block !important;
    font-size: 18px !important;
    border-radius: 25px !important;
    height: 52px;
    color: black !important;
    border: 1px solid black !important;
    margin-top: 10px;
    text-transform: uppercase;
  }

  .sv_q_checkbox_control_item {
    color: white !important;
  }

  .resourceTitleGroup {
    display: block;
    margin-top: 10px;
    font-weight: 700;
  }

  .sv_main.sv_default_css .sv_q_rating_item.active .sv_q_rating_item_text {
    background-color: #00a87b;
    border-color: #00a87b;
  }

  .sv_main.sv_default_css .sv_q_rating_item .sv_q_rating_item_text:hover {
    border-color: #00a87b;
  }

  .sv_page_title + div {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .sv_main.sv_default_css textarea:focus {
    border-color: #00a87b !important;
  }

  .sv_q_required_text {
    color: red;
  }

  span.mandatory {
    color: red;
  }
}
