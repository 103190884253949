// ---- Color config ----
@import "./colors";

$white: #fff !default;
$black: #000 !default;

// // Primary
// $orange: $orange-an; //
// // Complementary
// $teal: $aqua-an; // Aqua
// $green: $smaragd-an; // Smaragd
// $blue: $bla-an; // Blå
// $purple: $purpur-an; // Purpur
// $red: $rod-an; // Röd
// $brown: $brun-an; // Brun

// Load Paul Tol's colors
$blue: $blue-graph;
$indigo: $indigo-graph;
$purple: $purple-graph;
$pink: $pink-graph;
$red: $red-graph;
$orange: $orange-graph;
$yellow: $yellow-graph;
$green: $green-graph;
$teal: $teal-graph;
$cyan: $cyan-graph;

$body-color: #2c2c2c;
$border-width: 2px;

// Override bootstrap colours
// $primary: $orange-an;
$primary: #004411;
// $secondary: $grey;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $rod-an;

// ---- Font config ----
@import url("https://use.typekit.net/vij7dsy.css");
// Primary (sans-serif) font tries to use proxima nova
$font-family-sans-serif: "DM Sans", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "DM Sans";
$font-size-base: 1rem;

// ---- Navbar-theme-variables ----
// $navbar-dark-color: rgba($white, 0.65) !default;
// $navbar-dark-hover-color: rgba($white, 0.85) !default;
// $navbar-dark-active-color: $white !default;
// $navbar-dark-disabled-color: rgba($white, 0.4) !default;
// $navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

// $navbar-light-color: $white !default;
// $navbar-light-hover-color: $white !default;
// $navbar-light-active-color: $white !default;
// $navbar-light-disabled-color: rgba($black, 0.4) !default;
// $navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-light-toggler-border-color: rgba($black, 0.1) !default;

// $navbar-light-brand-color: $navbar-light-active-color !default;
// $navbar-light-brand-hover-color: $navbar-light-active-color !default;
// $navbar-dark-brand-color: $navbar-dark-active-color !default;
// $navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
