.home-page {
  .screen-dump-survey {
    margin-top: 50px;
    width: 350px;
    margin-left: -50px;
  }
  .screen-dump-result {
    width: 350px;
    margin-top: -125px;
    margin-left: 100px;
  }
}
