// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

@import "./variables";

// ===> Load bootstrap ==>
@import "../../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
@import "./main.scss";

// FIXME: This is a temporary fix to make all focused items dashed red
// *:focus {
//   outline: 4px dashed $secondary !important;
// }
