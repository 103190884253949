$width: 24px;

#progress {
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  font-weight: 700;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
}

#progress li {
  display: flex;
  align-items: center;
  float: left;
  height: 50px;
  padding: 10px 5px 10px 35px;
  background: #ccc;
  color: #888;
  position: relative;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 9.7%;
  margin: 0 1px;
  cursor: pointer;
}

#progress li:first-child {
  padding-left: 20px;
  color: white;
}

#progress li:not(:first-child):before {
  content: "";
  border-left: $width solid #fff;
  border-top: $width solid transparent;
  border-bottom: $width solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

#progress li:after {
  content: "";
  border-left: $width solid #ccc;
  border-top: $width solid transparent;
  border-bottom: $width solid transparent;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 20;
}

#progress li.active {
  background: #004411;
  color: white;
}

#progress li.done {
  background: #00a87b;
  color: white;
}

#progress li.active:after {
  border-left-color: #004411;
}

#progress li.done:after {
  border-left-color: #00a87b;
}
